import React, { createContext, ReactChild, ReactChildren, useContext, useState } from "react";
import { API_KEY, BASE_URL } from "../env";
import useFetch from "../hooks/utilities/useFetch";

interface AuthContextData {
    tokenAvailable: boolean;
    token: string | undefined;
    hash: string | undefined;
    loading: boolean;
    error: Error | undefined;
}

interface AuthProviderProps {
    children: ReactChild | ReactChildren;    
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

    const { 0: hash } = useState<string | undefined>();
    const { loading, error, value } = useFetch(`${BASE_URL}getToken.json?api_key=${API_KEY}`, []);

    return (
        <AuthContext.Provider value={{
            tokenAvailable: !!value?.token,
            token: value?.token,
            hash,
            loading,
            error,
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}