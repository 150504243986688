import React from 'react';
import downloadApp from '../../assets/download-app.png';

interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer>
      <div className="download">
        <a href="https://l.proside.pt/sxJv" title="Download App">
          <img src={downloadApp} alt="Download app" />
        </a>
      </div>
      <h2>Powered by Proximo®</h2>
    </footer>
  );
}

export default Footer;
