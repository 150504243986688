import { Routes, Route } from 'react-router-dom';
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Loading from './components/Loading';
import { useAuth } from './context/auth';
import Home from './pages/Home';

const App = () => {
  const { tokenAvailable } = useAuth();
  
  if (!tokenAvailable) {
    return <Loading />;
  }

  return (
    <div className="container">
      <Header />
      <main>
        <Routes>
          <Route path="/:hash" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </div>
  )
}

export default App;