import React from "react";
import { useLayout } from "../../context/layoutContext";

interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {
  const { image, description } = useLayout();
  return (
    <header>
      <img src={image} alt="Logo" />
      {description}
    </header>
  );
}

export default Header;
