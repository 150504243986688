import { useState } from "react";
import { useLocalStorage } from "../hooks/utilities/useStorage";

interface TicketsListProps {}

const TicketsList: React.FC<TicketsListProps> = () => {
  const [tickets] = useLocalStorage("tickets", []);

  const [ticketIndex, setTicketIndex] = useState<number>(-1);

  function onClickTicket(index: number) {
    setTicketIndex(index);
  }

  if (tickets === []) {
    return (
      <>
        <h1>Não foram encontrados tickets</h1>
      </>
    );
  }

  return (
    <>
      <h1 className="ticketsTitle">As suas senhas</h1>
      {ticketIndex < 0 ? (
        <div className="ticketsList">
          {tickets.map((element: any, index: number) => (
            <img key={element.next} className="ticketsImg" src={element.img} onClick={() => { onClickTicket(index) }} width="100%" alt="Ticket" />
          ))}
        </div>
      ) : (
        <div>
          <p className="backButton" onClick={() => { onClickTicket(-1) }}>←</p>
          <img src={tickets[ticketIndex].img} className="fullImage" onClick={() => { onClickTicket(-1) }} width="100%" alt="Ticket" />
        </div>
      )}
      
    </>
  );
};

export default TicketsList;
