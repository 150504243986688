import React, { useState } from "react";
// import useStateWithValidation from "../hooks/utilities/useStateWithValidation";

interface SmsDialogProps {
  hash?: String;
  queue: Number | undefined;
  onSubmit: any;
}

const SmsDialog: React.FC<SmsDialogProps> = ({ hash, queue, onSubmit }) => {
  // const [state, onChange, isValid] = useStateWithValidation<string | number>((value: string | number) => {
  //   return true;
  // }, '');
  
  const [state, onChange] = useState('');
  let isValid = false;
  
  return (
    <div className="sms-dialog">
      <p>Se introduzir o seu nº de telemóvel irá ser notificado quando estiver a chegar a sua vez, bem como quando estiver a ser chamado.</p>

      <form>
        <input type="number" id="number" name="number" value={state} title="Phone" onChange={(e) => onChange(e.target.value)} placeholder="Introduza o número de telemóvel" />
        <p>Se não introduzir o nº de telemóvel, deverá manter sempre aberta a página web onde vai estar a sua senha digital.</p>
        <button onClick={() => onSubmit(state)}>{isValid ? 'Continuar' : 'Continuar sem telemóvel'}</button>
      </form>

    </div>
  );
};

export default SmsDialog;
