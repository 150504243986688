import React from "react";
import { QueueData } from "../models/queue";
import Queue from "./Queue";

interface QueueListProps {
  hash?: string;
  queues?: QueueData[];
  setPopup: any;
}

const QueueList: React.FC<QueueListProps> = ({ queues = [], setPopup }) => {

  return (
    <>
        <div className="queue-container">
          {queues.map((element: any) => <Queue key={element.id} element={element} setPopup={setPopup} />)}
        </div>
    </>
  );
};

export default QueueList;
