import { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import { API_KEY, BASE_URL } from "../env";
import useFetch from "../hooks/utilities/useFetch";
import { useLocalStorage } from "../hooks/utilities/useStorage";

interface TicketProps {
  hash?: String;
  queue: Number | undefined;
  phone?: Number | undefined;
}
const Ticket: React.FC<TicketProps> = ({ hash, queue, phone }) => {

  const { token } = useAuth();
  const finalURL = typeof phone == "undefined" 
    ? `${BASE_URL}getQueue.json/${token}/${hash}/${queue}?api_key=${API_KEY}`
    : `${BASE_URL}getQueue.json/${token}/${hash}/${queue}?mobile=${phone}&api_key=${API_KEY}`;
  
  const request = useFetch(finalURL, [token]);
  const [tickets, setTickets] = useLocalStorage('tickets', []);

  const [control, setControl] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  
  useEffect( () => {
    let loopControl = true;
    tickets.forEach((element: any) => {
      if (element === request.value) {
        loopControl = false;
        return;
      }
    });

    if(loopControl){
      setControl(false);
    }
    
  }, [request.loading, request.value, setTickets, tickets])

  useEffect(() => {
    if (!request.loading && !control) {
      const newTickets = [...tickets, request.value];
      setTickets(newTickets);
      setLoading(false);
    }
  }, [control, request.loading, request.value, setTickets, tickets]);

  return (
    <>
      {!loading ? <img src={request.value?.img} alt="Ticket" width="100%" /> : <></>}
    </>
  );
}

export default Ticket;
