import React from "react";
import { QueueData } from "../models/queue";

interface QueueProps {
  element: QueueData;
  setPopup: (value: boolean, id: any) => void;
}

const Queue: React.FC<QueueProps> = ({ element, setPopup }) => (
  <div role="button" className="item" onClick={() => setPopup(true, element.id)}>
    <div style={{ backgroundColor: element.color }} className="letter-container">
      <p className="label">
        <strong>{element.letter}</strong>
      </p>
    </div>
    <div className="label-container">
        <p className="description">{element.name}</p>
        <img className="icon" draggable="false" src={element.img} alt="Queue icon" />
    </div>
  </div>
);

export default Queue;
